<template>
  <div>
    <div class="row  index-icon justify-content-md-center ">
      <i class="rc-Ativo-38 "></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OperationalIndex',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' - %s'
    }
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  },
  watch: {

  }
}
</script>
